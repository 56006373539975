module.exports = [{
      plugin: require('../../../themes/paws/gatsby-browser.tsx'),
      options: {"plugins":[],"brandId":"A70172A0-2217-432F-B786-387BFB40A167","productRef":"AOF"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/packages/themes/agria/src/images/icon-512x512.png","icon_options":{"purpose":"any maskable"},"name":"Agria Pet Insurance","short_name":"Agria","start_url":"/","background_color":"#02315B","theme_color":"#02315B","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1864b9fcc160722ec00366f136cbe20f"},
    },{
      plugin: require('../../../themes/agria/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/qab/gatsby-browser.tsx'),
      options: {"plugins":[],"companyName":"Agria Pet Insurance","contactNumber":"03330 30 10 00","footerTerms":"\n          <p>\n            For UK customers: <br />\n\n            Agria Pet Insurance Ltd is authorised and regulated by the Financial Conduct Authority, Financial Services Register Number 496160. Agria Pet Insurance Ltd is registered and incorporated in England and Wales with registered number 04258783. Registered office: First Floor, Blue Leanie, Walton Street, Aylesbury, Buckinghamshire, HP21 7QW. Agria insurance policies are underwritten by Agria Forsakring who is authorised and regulated by the Prudential Regulatory Authority and Financial Conduct Authority.\n          </p>\n\n          <p>\n          <br />\n          For Jersey customers: <br />\n          \n          Agria Pet Insurance Ltd is regulated by the Jersey Financial Services Commission (JFSC). Ref: 0001498. Registered office: As detailed above.\n          </p>\n          \n          <p>\n          <br />\n            For Guernsey customers: <br />\n          \n            Clegg Gifford Channel Islands Limited is licensed by the Guernsey Financial Services Commission. Ref: 2722221. Registered office: Admiral House, Place Du Commerce, St Peter Port, Guernsey GY1 2AT.\n          </p>\n        ","trustpilotUrl":"https://www.trustpilot.com/review/agriapet.co.uk","allowedSpecies":["Cat","Dog"],"isFreeJourney":true,"isLitter":false},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
